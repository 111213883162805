 <div id="bgimg" class="bgimg">
<br>
   <div class="bgimg2" *ngIf="!isMobileResolution">
     <p align="justify"><span style="font-family: 'Open Sans', sans-serif; line-height: 150%; font-size: 18pt;">
       The following interactive map gives an overview of all markers as well as all marking projects contained in the
       Fish Trek database. For more information, please click on the respective symbol on the map.
</span></p>
   </div>
   <div class="bgimg2" *ngIf="isMobileResolution">
     <p align="justify"><span style="font-family: 'Open Sans', sans-serif; line-height: 150%; font-size: 16pt;">
       The following interactive map gives an overview of all markers as well as all marking projects contained in the
       Fish Trek database. For more information, please click on the respective symbol on the map.
     </span></p>
       <br>
     </div>
   <div  *ngIf="screenOrientation.type===screenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY"><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br></div>

   <div id="map-frame" class="map-frame">
        <div id="map"></div>
      </div>
</div>
